import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Button,
    Input,
    VStack,
    HStack,
    useToast,
    FormControl,
    FormLabel
} from '@chakra-ui/react';
import { getArenaState, patchArenaState } from '../api/arena/arena.ts';
import Loading from './Loading';

import { useColors } from '../constants/constants.js';
import { useAuth } from '../context/AuthContext.jsx';

const AdminPanel = () => {
    const [arena, setArena] = useState({})
    const [numSimultaneousMatches, setNumSimultaneousMatches] = useState(null);
    const [hasLoaded, setLoaded] = useState(false);
    const [numberChanged, setNumberChanged] = useState(0);
    const toast = useToast();
    const colors = useColors();
    const { authState } = useAuth();

    const fetchArena = useCallback(() => {
        getArenaState(authState.options).then(
            (response) => {
                setArena(response);
                setNumSimultaneousMatches(response.parallel_match);
                setLoaded(true);
            }
        ).catch(
            () => {
                toast({
                    title: "Error fetching arena.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        );
    }, [setArena, setNumSimultaneousMatches, setLoaded, toast, authState.options]);


    useEffect(() => {
        fetchArena();
    }, [fetchArena]);

    const handleNumberMatch = async () => {
        try {
            await patchArenaState({parallel_match: numSimultaneousMatches}, authState.options);
            fetchArena();
            toast({
                title: "Number of parallel match updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error updating parallel match.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleChange = (value) => {
        setNumSimultaneousMatches(value);
        if (value === arena.parallel_match)
            setNumberChanged(0)
        else
            setNumberChanged(1) 
    }


    const handleState = async () => {
        // Logic to start matches
        try {
            await patchArenaState({state: !arena.state}, authState.options);
            fetchArena();
            toast({
                title: "Arena state updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error updating arena state.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    if (!hasLoaded) return <Loading />;

    return (
        <Box p={5} bg={colors.panelBg} borderRadius="md" mx="auto" w="50%" boxShadow="lg">
            <VStack spacing={4}>
                <Box fontSize="xl" fontWeight="bold" mb={4}>
                    Configuration des Matchs
                </Box>
                <FormControl>
                    <HStack justify="space-between">
                        <FormLabel w="50%" textAlign="left" fontWeight="bold">Nombre de matchs simultanés</FormLabel>
                        <Input
                            w="50%"
                            type="number"
                            value={numSimultaneousMatches}
                            onChange={(e) => handleChange(e.target.value)}
                            borderColor="cyan.600"
                            focusBorderColor="cyan.400"
                        />
                        <Button colorScheme="blue" onClick={handleNumberMatch} isDisabled={!numberChanged}>
                            Modifier
                        </Button>
                    </HStack>
                </FormControl>
                <HStack spacing={4} mt={15}>
                    <Button colorScheme="green" onClick={handleState} isDisabled={arena.state}>
                        Démarrer les matchs
                    </Button>
                    <Button colorScheme="red" onClick={handleState} isDisabled={!arena.state}>
                        Arrêter les matchs
                    </Button>
                </HStack>
            </VStack>
        </Box>
    );
};

export default AdminPanel;
