import { Box, Flex, Text, Tooltip, Icon, VStack, HStack, Divider, useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { FightStatus } from '../constants/constants';
import { CloseIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import { CustomStatsInfos } from '../config';
import { useColors } from '../constants/constants';
import LoadingDots from './LoadingDots.js';

const FightCard = ({ fightData, status, myAgents, groupData}) => {
  const [fight, setFight] = useState(fightData);
  const colors = useColors();

  useEffect(() => {
    const fetchGroupData = async () => {
      if (fight && groupData) {
        const groupStats = groupData[fight.player1.group.id];
        const player1Stats = groupStats.agents.find(agent => agent.id === fight.player1.id);
        setFight(prevFight => ({
          ...prevFight,
          player1: {
            ...prevFight.player1,
            n_victories: player1Stats.n_victories,
            n_defeats: player1Stats.n_defeats,
            n_points_conceded: player1Stats.n_points_conceded,
            n_points_scored: player1Stats.n_points_scored,
          }
        }));

        const group2Stats = groupData[fight.player2.group.id];
        const player2Stats = group2Stats.agents.find(agent => agent.id === fight.player2.id);
        setFight(prevFight => ({
          ...prevFight,
          player2: {
            ...prevFight.player2,
            n_victories: player2Stats.n_victories,
            n_defeats: player2Stats.n_defeats,
            n_points_conceded: player2Stats.n_points_conceded,
            n_points_scored: player2Stats.n_points_scored,
          }
        }));
      }
    };
    
    fetchGroupData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setFight(prevFight => ({ ...prevFight })); // Force component to re-render
    }, 60000); // Update every 60 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);
  
  const winnerStyle = {
    bg: useColorModeValue('green.100', 'green.900'),
    color: useColorModeValue('green.800', 'green.200')
  };
  const loserStyle = {
    bg: useColorModeValue('red.100', 'red.900'),
    color: useColorModeValue('red.800', 'red.100')
  };
  const drawStyle = {
    bg: useColorModeValue('cyan.100', 'cyan.900'),
    color: useColorModeValue('cyan.800', 'cyan.200')
  };
  const in_progressStyle = {
    bg: useColorModeValue('yellow.100', 'yellow.900'),
    color: useColorModeValue('yellow.800', 'yellow.200')
  };
  const cancelledStyle = {
    bg: useColorModeValue('pink.100', 'pink.900'),
    color: useColorModeValue('pink.800', 'pink.200')
  };

  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const textColor = useColorModeValue('teal.700', 'teal.200');
  const tooltipBgColor = useColorModeValue('teal.300', 'teal.800');
  const borderColor = useColorModeValue('gray.800', 'gray.200');

  const computeRemainingTime = (date) => {
    const diff = new Date(date) - new Date();
    if (diff <= 0) return '0h 0m';
    const hours = Math.floor(diff / 1000 / 60 / 60);
    const minutes = Math.floor(diff / 1000 / 60) - hours * 60;
    return `${hours}h ${minutes}m`;
  };


  const computeElapsedTime = (date) => {
    console.log(date, new Date())
    const diff = new Date() - new Date(date);
    if (diff <= 0) return '0m';
    const minutes = Math.floor(diff / 1000 / 60);
    return `${minutes}m`;
  };

  const handleClick = () => {
    if ([FightStatus.DONE, FightStatus.IN_PROGRESS].includes(status)) {
      window.open(`/fight?id=${fight.id}`, '_blank');
    }
  }
  console.log(fight);
  const opponentInfo = (player, bgColor, textColor) => (
    <VStack align="center" spacing={2} p={3} bg={bgColor} borderRadius="md" boxShadow="lg">
      <Text fontSize="lg" fontWeight="bold" color={textColor}>{player.name}</Text>
      <Text fontSize="sm" color={textColor}>Elo : <span style={{ fontWeight: 'bold' }}>{player.elo}</span></Text>
      <Text fontSize="sm" color={textColor}>Victoires: < span style={{ fontWeight: 'bold' }}>{player.n_victories}</span></Text>
      <Text fontSize="sm" color={textColor}>Défaites: <span style={{ fontWeight: 'bold' }}>{player.n_defeats}</span></Text>
      <Text fontSize="sm" color={textColor}>Points marqués: <span style={{ fontWeight: 'bold' }}>{player.n_points_scored}</span></Text>
      <Text fontSize="sm" color={textColor}>Points encaissés: <span style={{ fontWeight: 'bold' }}>{player.n_points_conceded}</span></Text>

      {CustomStatsInfos.map((stat) => (
        <Text key={stat.key} fontSize="sm" color={textColor}>
          {stat.title}: <span style={{ fontWeight: 'bold' }}>
            {player.custom_stats
              .filter(customStat => customStat.name === stat.key)
              .reduce((acc, curr) => acc + curr.value, 0) ?? 0}
          </span>
        </Text>
      ))}


    </VStack>
  );



  if (!fight) return

  return (
    <Flex
      bg={colors.bgColor}
      boxShadow="md"
      borderRadius="3xl"
      overflow="hidden"
      transition="transform 0.2s, box-shadow 0.2s"
      _hover={[FightStatus.DONE, FightStatus.IN_PROGRESS].includes(status) ? { transform: 'scale(1.02)', boxShadow: 'xl' } : {}}
      p={6}
      align="center"
      justify="space-between"
      w="full"
      cursor={[FightStatus.DONE, FightStatus.IN_PROGRESS].includes(status)  ? 'pointer' : 'default'}
      onClick={handleClick}
    >
      {/* Left section */}
      <Box
        flex="1"
        textAlign="center"
        color={status === FightStatus.DONE ?
          (fight.score_player1 >= fight.score_player2 ? winnerStyle.color : loserStyle.color) :
          status === FightStatus.IN_PROGRESS ? in_progressStyle.color :
            status === FightStatus.CANCELLED ? cancelledStyle.color : drawStyle.color}
        bg={status === FightStatus.DONE ?
          (fight.score_player1 >= fight.score_player2 ? winnerStyle.bg : loserStyle.bg) :
          status === FightStatus.IN_PROGRESS ? in_progressStyle.bg :
            status === FightStatus.CANCELLED ? cancelledStyle.bg : drawStyle.bg}

        borderColor={borderColor}
        py={4}
        px={2}
        borderRadius="2xl"
        borderWidth={myAgents && myAgents.includes(fight.player1.id) ? '2px' : '0px'}
      >
        <Text fontSize="xl" fontWeight="bold" isTruncated>
          {fight.player1.group.name}
        </Text>
        <Text fontSize="sm" isTruncated>
          {fight.player1.name} ({parseInt(fight.player1.elo)} Elo)
        </Text>
      </Box>

      {/* Middle section */}
      {status === FightStatus.DONE ?
        (
          <Box flex="1" textAlign="center" color={drawStyle.color}>
            <Text fontSize="3xl" fontWeight="bold">
              {`${fight.score_player1} - ${fight.score_player2}`}
            </Text>
            <Text fontSize="md" color={drawStyle.color} mt={2}>
              {new Date(fight.date).toLocaleDateString('ca-CA', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}
            </Text>
          </Box>
        ) : status === FightStatus.SCHEDULED ? (
          <Box flex="1" textAlign="center" color={drawStyle.color}>
            <Text fontSize="2xl" color={colors.blackTextColor} fontWeight="bold">
              Prévu dans
            </Text>
            <Text fontSize="2xl" fontWeight="bold" mt={2} color={drawStyle.color}>
              {computeRemainingTime(fight.scheduled_date)}
            </Text>
          </Box>
        ) : status === FightStatus.IN_PROGRESS ? (
          <Box flex="1" textAlign="center" color={in_progressStyle.color}>
            <LoadingDots />
            <Text fontSize="2xl" fontWeight="bold" mt={2} color={drawStyle.color}>
              {computeElapsedTime(fight.date)}
            </Text>
          </Box>
        ) : (
          <Box flex="1" textAlign="center" color={cancelledStyle.color}>
            <Text fontSize="2xl" color={colors.blackTextColor} fontWeight="bold" mb={2}>
              Annulé
            </Text>
            <CloseIcon w={7} h={7} color={cancelledStyle.color} />
          </Box>
        )}

      {/* Right section */}
      <Box
        flex="1"
        textAlign="center"
        color={status === FightStatus.DONE ?
          (fight.score_player2 >= fight.score_player1 ? winnerStyle.color : loserStyle.color) :
          status === FightStatus.IN_PROGRESS ? in_progressStyle.color :
            status === FightStatus.CANCELLED ? cancelledStyle.color : drawStyle.color}
        bg={status === FightStatus.DONE ?
          (fight.score_player2 >= fight.score_player1 ? winnerStyle.bg : loserStyle.bg) :
          status === FightStatus.IN_PROGRESS ? in_progressStyle.bg :
            status === FightStatus.CANCELLED ? cancelledStyle.bg : drawStyle.bg}
        py={4}
        px={2}
        borderRadius="2xl"
        borderColor={borderColor}
        borderWidth={myAgents && myAgents.includes(fight.player2.id) ? '2px' : '0px'}
      >
        <Text fontSize="xl" fontWeight="bold" isTruncated>
          {fight.player2.group.name}
        </Text>
        <Text fontSize="sm" isTruncated>
          {fight.player2.name} ({parseInt(fight.player2.elo)} Elo)
        </Text>
      </Box>

      {/* Info Icon */}
      <Tooltip label={
        <HStack spacing={4} p={2} bg={'rbga(0,0,0,0)'}>
          {opponentInfo(fight.player1, bgColor, textColor)}
          <Divider orientation="vertical" borderColor="white" borderWidth="2px" height="100px" />
          {opponentInfo(fight.player2, bgColor, textColor)}
        </HStack>
      } placement="top" bg={tooltipBgColor} color={'white'} borderRadius="md" maxWidth={1000}>
        <Box>
          <Icon as={InfoOutlineIcon} w={5} h={5} color="gray.500" ml={2} />
        </Box>
      </Tooltip>
    </Flex>
  );
};

FightCard.propTypes = {
  fightData: PropTypes.object.isRequired,
  status: PropTypes.oneOf([FightStatus.CANCELLED, FightStatus.DONE, FightStatus.IN_PROGRESS, FightStatus.SCHEDULED]).isRequired,
  myAgents: PropTypes.array.isRequired,
  groupData: PropTypes.array.isRequired
}

export default FightCard;
