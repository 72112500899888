/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Abyss API
 * API for the Abyss project
 * OpenAPI spec version: 0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CustomStats,
  Fight,
  FightPost,
  GetFightCollectionParams,
  UpdateFight
} from '.././model'
import { axios_instance } from '.././mutator/axios-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const postFightCollection = (
    fightPost: FightPost,
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<Fight>(
      {url: `https://abyss-a24.corail-lab.ca/api/fights`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: fightPost
    },
      options);
    }
  


export const getPostFightCollectionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFightCollection>>, TError,{data: FightPost}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof postFightCollection>>, TError,{data: FightPost}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFightCollection>>, {data: FightPost}> = (props) => {
          const {data} = props ?? {};

          return  postFightCollection(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFightCollectionMutationResult = NonNullable<Awaited<ReturnType<typeof postFightCollection>>>
    export type PostFightCollectionMutationBody = FightPost
    export type PostFightCollectionMutationError = unknown

    export const usePostFightCollection = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFightCollection>>, TError,{data: FightPost}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFightCollection>>,
        TError,
        {data: FightPost},
        TContext
      > => {

      const mutationOptions = getPostFightCollectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getFightCollection = (
    params?: GetFightCollectionParams,
 options?: SecondParameter<typeof axios_instance>,signal?: AbortSignal
) => {
      
      
      return axios_instance<Fight[]>(
      {url: `https://abyss-a24.corail-lab.ca/api/fights`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetFightCollectionQueryKey = (params?: GetFightCollectionParams,) => {
    return [`https://abyss-a24.corail-lab.ca/api/fights`, ...(params ? [params]: [])] as const;
    }

    
export const getGetFightCollectionQueryOptions = <TData = Awaited<ReturnType<typeof getFightCollection>>, TError = unknown>(params?: GetFightCollectionParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightCollection>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFightCollectionQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFightCollection>>> = ({ signal }) => getFightCollection(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFightCollection>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFightCollectionQueryResult = NonNullable<Awaited<ReturnType<typeof getFightCollection>>>
export type GetFightCollectionQueryError = unknown

export const useGetFightCollection = <TData = Awaited<ReturnType<typeof getFightCollection>>, TError = unknown>(
 params?: GetFightCollectionParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightCollection>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFightCollectionQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getFightMatchmaking = (
    
 options?: SecondParameter<typeof axios_instance>,signal?: AbortSignal
) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/fights/compute_matchmaking`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFightMatchmakingQueryKey = () => {
    return [`https://abyss-a24.corail-lab.ca/api/fights/compute_matchmaking`] as const;
    }

    
export const getGetFightMatchmakingQueryOptions = <TData = Awaited<ReturnType<typeof getFightMatchmaking>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightMatchmaking>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFightMatchmakingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFightMatchmaking>>> = ({ signal }) => getFightMatchmaking(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFightMatchmaking>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFightMatchmakingQueryResult = NonNullable<Awaited<ReturnType<typeof getFightMatchmaking>>>
export type GetFightMatchmakingQueryError = unknown

export const useGetFightMatchmaking = <TData = Awaited<ReturnType<typeof getFightMatchmaking>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightMatchmaking>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFightMatchmakingQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const postCustomItem = (
    customStats: CustomStats,
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<CustomStats>(
      {url: `https://abyss-a24.corail-lab.ca/api/fights/custom_stat`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: customStats
    },
      options);
    }
  


export const getPostCustomItemMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCustomItem>>, TError,{data: CustomStats}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof postCustomItem>>, TError,{data: CustomStats}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCustomItem>>, {data: CustomStats}> = (props) => {
          const {data} = props ?? {};

          return  postCustomItem(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostCustomItemMutationResult = NonNullable<Awaited<ReturnType<typeof postCustomItem>>>
    export type PostCustomItemMutationBody = CustomStats
    export type PostCustomItemMutationError = unknown

    export const usePostCustomItem = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCustomItem>>, TError,{data: CustomStats}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postCustomItem>>,
        TError,
        {data: CustomStats},
        TContext
      > => {

      const mutationOptions = getPostCustomItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getFightGroup = (
    groupId: number,
 options?: SecondParameter<typeof axios_instance>,signal?: AbortSignal
) => {
      
      
      return axios_instance<Fight[]>(
      {url: `https://abyss-a24.corail-lab.ca/api/fights/group/${groupId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFightGroupQueryKey = (groupId: number,) => {
    return [`https://abyss-a24.corail-lab.ca/api/fights/group/${groupId}`] as const;
    }

    
export const getGetFightGroupQueryOptions = <TData = Awaited<ReturnType<typeof getFightGroup>>, TError = unknown>(groupId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightGroup>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFightGroupQueryKey(groupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFightGroup>>> = ({ signal }) => getFightGroup(groupId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(groupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFightGroup>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFightGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getFightGroup>>>
export type GetFightGroupQueryError = unknown

export const useGetFightGroup = <TData = Awaited<ReturnType<typeof getFightGroup>>, TError = unknown>(
 groupId: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightGroup>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFightGroupQueryOptions(groupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getFightItemJson = (
    id: number,
 options?: SecondParameter<typeof axios_instance>,signal?: AbortSignal
) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/fights/json/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFightItemJsonQueryKey = (id: number,) => {
    return [`https://abyss-a24.corail-lab.ca/api/fights/json/${id}`] as const;
    }

    
export const getGetFightItemJsonQueryOptions = <TData = Awaited<ReturnType<typeof getFightItemJson>>, TError = unknown>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightItemJson>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFightItemJsonQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFightItemJson>>> = ({ signal }) => getFightItemJson(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFightItemJson>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFightItemJsonQueryResult = NonNullable<Awaited<ReturnType<typeof getFightItemJson>>>
export type GetFightItemJsonQueryError = unknown

export const useGetFightItemJson = <TData = Awaited<ReturnType<typeof getFightItemJson>>, TError = unknown>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightItemJson>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFightItemJsonQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const postFightLoadCsv = (
    
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/fights/loadCSV`, method: 'POST'
    },
      options);
    }
  


export const getPostFightLoadCsvMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFightLoadCsv>>, TError,void, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof postFightLoadCsv>>, TError,void, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFightLoadCsv>>, void> = () => {
          

          return  postFightLoadCsv(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostFightLoadCsvMutationResult = NonNullable<Awaited<ReturnType<typeof postFightLoadCsv>>>
    
    export type PostFightLoadCsvMutationError = unknown

    export const usePostFightLoadCsv = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFightLoadCsv>>, TError,void, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postFightLoadCsv>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getPostFightLoadCsvMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteFightItem = (
    id: number,
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/fights/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFightItemMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFightItem>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFightItem>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFightItem>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteFightItem(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFightItemMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFightItem>>>
    
    export type DeleteFightItemMutationError = unknown

    export const useDeleteFightItem = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFightItem>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFightItem>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getDeleteFightItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getFightItem = (
    id: number,
 options?: SecondParameter<typeof axios_instance>,signal?: AbortSignal
) => {
      
      
      return axios_instance<Fight>(
      {url: `https://abyss-a24.corail-lab.ca/api/fights/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFightItemQueryKey = (id: number,) => {
    return [`https://abyss-a24.corail-lab.ca/api/fights/${id}`] as const;
    }

    
export const getGetFightItemQueryOptions = <TData = Awaited<ReturnType<typeof getFightItem>>, TError = unknown>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightItem>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFightItemQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFightItem>>> = ({ signal }) => getFightItem(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFightItem>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFightItemQueryResult = NonNullable<Awaited<ReturnType<typeof getFightItem>>>
export type GetFightItemQueryError = unknown

export const useGetFightItem = <TData = Awaited<ReturnType<typeof getFightItem>>, TError = unknown>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFightItem>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFightItemQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const patchFightItem = (
    id: number,
    updateFight: UpdateFight,
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<Fight>(
      {url: `https://abyss-a24.corail-lab.ca/api/fights/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateFight
    },
      options);
    }
  


export const getPatchFightItemMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFightItem>>, TError,{id: number;data: UpdateFight}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchFightItem>>, TError,{id: number;data: UpdateFight}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFightItem>>, {id: number;data: UpdateFight}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFightItem(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchFightItemMutationResult = NonNullable<Awaited<ReturnType<typeof patchFightItem>>>
    export type PatchFightItemMutationBody = UpdateFight
    export type PatchFightItemMutationError = unknown

    export const usePatchFightItem = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFightItem>>, TError,{id: number;data: UpdateFight}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchFightItem>>,
        TError,
        {id: number;data: UpdateFight},
        TContext
      > => {

      const mutationOptions = getPatchFightItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    