/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Abyss API
 * API for the Abyss project
 * OpenAPI spec version: 0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetGroupCollectionParams,
  GetGroupItemParams,
  Group,
  GroupPost,
  UpdateGroup
} from '.././model'
import { axios_instance } from '.././mutator/axios-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const postGroupCollection = (
    groupPost: GroupPost,
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<Group>(
      {url: `https://abyss-a24.corail-lab.ca/api/groups`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: groupPost
    },
      options);
    }
  


export const getPostGroupCollectionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postGroupCollection>>, TError,{data: GroupPost}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof postGroupCollection>>, TError,{data: GroupPost}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postGroupCollection>>, {data: GroupPost}> = (props) => {
          const {data} = props ?? {};

          return  postGroupCollection(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostGroupCollectionMutationResult = NonNullable<Awaited<ReturnType<typeof postGroupCollection>>>
    export type PostGroupCollectionMutationBody = GroupPost
    export type PostGroupCollectionMutationError = unknown

    export const usePostGroupCollection = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postGroupCollection>>, TError,{data: GroupPost}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postGroupCollection>>,
        TError,
        {data: GroupPost},
        TContext
      > => {

      const mutationOptions = getPostGroupCollectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getGroupCollection = (
    params?: GetGroupCollectionParams,
 options?: SecondParameter<typeof axios_instance>,signal?: AbortSignal
) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/groups`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetGroupCollectionQueryKey = (params?: GetGroupCollectionParams,) => {
    return [`https://abyss-a24.corail-lab.ca/api/groups`, ...(params ? [params]: [])] as const;
    }

    
export const getGetGroupCollectionQueryOptions = <TData = Awaited<ReturnType<typeof getGroupCollection>>, TError = unknown>(params?: GetGroupCollectionParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupCollection>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGroupCollectionQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroupCollection>>> = ({ signal }) => getGroupCollection(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getGroupCollection>>, TError, TData> & { queryKey: QueryKey }
}

export type GetGroupCollectionQueryResult = NonNullable<Awaited<ReturnType<typeof getGroupCollection>>>
export type GetGroupCollectionQueryError = unknown

export const useGetGroupCollection = <TData = Awaited<ReturnType<typeof getGroupCollection>>, TError = unknown>(
 params?: GetGroupCollectionParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupCollection>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetGroupCollectionQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const postGroupLoadCsv = (
    
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/groups/loadCSV`, method: 'POST'
    },
      options);
    }
  


export const getPostGroupLoadCsvMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postGroupLoadCsv>>, TError,void, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof postGroupLoadCsv>>, TError,void, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postGroupLoadCsv>>, void> = () => {
          

          return  postGroupLoadCsv(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostGroupLoadCsvMutationResult = NonNullable<Awaited<ReturnType<typeof postGroupLoadCsv>>>
    
    export type PostGroupLoadCsvMutationError = unknown

    export const usePostGroupLoadCsv = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postGroupLoadCsv>>, TError,void, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postGroupLoadCsv>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getPostGroupLoadCsvMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteGroupItem = (
    id: number,
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/groups/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteGroupItemMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteGroupItem>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteGroupItem>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteGroupItem>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteGroupItem(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteGroupItemMutationResult = NonNullable<Awaited<ReturnType<typeof deleteGroupItem>>>
    
    export type DeleteGroupItemMutationError = unknown

    export const useDeleteGroupItem = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteGroupItem>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteGroupItem>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getDeleteGroupItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getGroupItem = (
    id: number,
    params?: GetGroupItemParams,
 options?: SecondParameter<typeof axios_instance>,signal?: AbortSignal
) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/groups/${id}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetGroupItemQueryKey = (id: number,
    params?: GetGroupItemParams,) => {
    return [`https://abyss-a24.corail-lab.ca/api/groups/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetGroupItemQueryOptions = <TData = Awaited<ReturnType<typeof getGroupItem>>, TError = unknown>(id: number,
    params?: GetGroupItemParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupItem>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGroupItemQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroupItem>>> = ({ signal }) => getGroupItem(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getGroupItem>>, TError, TData> & { queryKey: QueryKey }
}

export type GetGroupItemQueryResult = NonNullable<Awaited<ReturnType<typeof getGroupItem>>>
export type GetGroupItemQueryError = unknown

export const useGetGroupItem = <TData = Awaited<ReturnType<typeof getGroupItem>>, TError = unknown>(
 id: number,
    params?: GetGroupItemParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupItem>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetGroupItemQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const patchGroupItem = (
    id: number,
    updateGroup: UpdateGroup,
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<Group>(
      {url: `https://abyss-a24.corail-lab.ca/api/groups/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateGroup
    },
      options);
    }
  


export const getPatchGroupItemMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchGroupItem>>, TError,{id: number;data: UpdateGroup}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchGroupItem>>, TError,{id: number;data: UpdateGroup}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchGroupItem>>, {id: number;data: UpdateGroup}> = (props) => {
          const {id,data} = props ?? {};

          return  patchGroupItem(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchGroupItemMutationResult = NonNullable<Awaited<ReturnType<typeof patchGroupItem>>>
    export type PatchGroupItemMutationBody = UpdateGroup
    export type PatchGroupItemMutationError = unknown

    export const usePatchGroupItem = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchGroupItem>>, TError,{id: number;data: UpdateGroup}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchGroupItem>>,
        TError,
        {id: number;data: UpdateGroup},
        TContext
      > => {

      const mutationOptions = getPatchGroupItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    