import React, { useState, useEffect } from 'react';
import {
  Container, Heading, useColorModeValue, Box, Tabs, TabList, Divider,
  TabPanels, Tab, TabPanel, Badge, Image, Flex, Grid, Stat, StatLabel, StatNumber, VStack
} from '@chakra-ui/react';
import Loading from './Loading';
import AgentList from './AgentList';
import { getStudentProfile } from '../api/students/students.ts';
import { getGroupItem, getGroupCollection } from '../api/groups/groups.ts';
import { getFightGroup } from '../api/fights/fights.ts';
import FightList from './FightList';
import ErrorMessage from './ErrorMessage';
import { useAuth } from '../context/AuthContext.jsx';
import { LeagueLogos, capitalize, AgentStatus, FightStatus, StatsInfos, useColors } from '../constants/constants.js';
import { useDisclosure } from '@chakra-ui/react';
import BannerSelectionModal from './BannerSelectionModal';
import { banners } from '../constants/constants.js';




const Profile = () => {
  const boxBgColor = useColorModeValue('white', 'gray.800');
  const headingColor = useColorModeValue('teal.500', 'teal.300');
  const statLabelColor = useColorModeValue('pink.500', 'pink.300');
  const statNumberColor = useColorModeValue('cyan.600', 'cyan.400');
  const [groupData, setGroupData] = useState(null);
  const [groups, setGroups] = useState(null);
  const [studentName, setStudentName] = useState("");
  const [previousFights, setPreviousFights] = useState([]);
  const [upcomingFights, setUpcomingFights] = useState([]);
  const { authState } = useAuth();
  const [selectedBanner, setSelectedBanner] = useState('/banners/banner.png');
  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const colors = useColors();

  useEffect(() => {
    if (banners.indexOf(localStorage.getItem('banner')) >= 0) {
      setSelectedBanner(`/banners/${localStorage.getItem('banner')}`);
    }  
  }, [setSelectedBanner])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (authState.student != null) {
          const responseStudent = await getStudentProfile(authState.options);
          setStudentName(responseStudent.username);
          const responseGroupe = await getGroupItem(responseStudent.group_id, { include_stats: true });
          responseGroupe.agents = responseGroupe.agents.filter(agent => agent.status !== AgentStatus.DELETED);
          setGroupData(responseGroupe);
          const responseFights = await getFightGroup(responseStudent.group_id);
          setUpcomingFights(responseFights.filter(fight => fight.status === FightStatus.SCHEDULED));
          setPreviousFights(responseFights.filter(fight => fight.status === FightStatus.DONE));
        }
      } catch (error) {
        setGroupData({});
      }
    };

    fetchData();
  }, [authState.student, authState.options]);

  useEffect(() => {
    const fetchGroups = async() => {
      const response = await getGroupCollection({include_stats: true});
      setGroups(response.reduce((acc, group) => {
        acc[group.id] = group;
        return acc;
      }, {}));
    }
    fetchGroups();
  }, [])

  if (!groupData || !groups) return <Loading />;
  if (Object.keys(groupData).length === 0 || groups.length === 0) return <ErrorMessage message="Erreur lors du chargement des données, vous devez peut-être vous déconnecter." />;

  const handleBannerSelect = (banner) => {
    localStorage.setItem('banner', banner);
    setSelectedBanner(`/banners/${banner}`);
    onClose();
  };

  return (
    <Container maxW={'7xl'} bg={colors.panelBg} padding={10} borderRadius="lg" boxShadow="xl" mt={10}>
      <Box mb={8} position="relative" display="flex" alignItems="center">
        <Box
          ml={4}
          flex="1"
          backgroundImage={`url(${selectedBanner})`}
          backgroundSize="cover"
          backgroundPosition="center"
          borderRadius="lg"
          padding={8}
          position="relative"
          onClick={onOpen}
          cursor="pointer"
        >
          <Box
            bg="rgba(100, 100, 100, 0.6)"
            borderRadius="lg"
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            padding={4}
            zIndex={1}
          />
          <Flex direction="row" align="center" justify="space-between" zIndex={2} position="relative">
            <Heading
              as="h1"
              size="2xl"
              color="white"
              textShadow="0 0 10px rgba(0, 0, 0, 0.7)"
            >
              {studentName}
            </Heading>
            <Flex direction="column" align="flex-end">
              <Heading
                as="h2"
                size="lg"
                color="white"
                textShadow="0 0 10px rgba(0, 0, 0, 0.7)"
                mb={2}
              >
                {groupData.name}
              </Heading>
              <Badge colorScheme="green" fontSize="lg">
                {groupData.elo} ELO
              </Badge>
            </Flex>
          </Flex>
        </Box>
      </Box>

      <Grid templateColumns={{ base: '1fr', md: '3fr 1fr' }} gap={10}>
        <Box bg={colors.bgColor} borderRadius="lg" padding={6} boxShadow="lg">
          <Tabs isFitted variant="soft-rounded">
            <TabList>
              <Tab
                fontWeight="bold"
                bg={colors.tabBg}
                color={colors.tabColor}
                _selected={{ bg: colors.tabSelectedBg, color: colors.tabSelectedColor }}
                mr={2}>
                Agents
              </Tab>
              <Tab
                fontWeight="bold"
                bg={colors.tabBg}
                color={colors.tabColor}
                _selected={{ bg: colors.tabSelectedBg, color: colors.tabSelectedColor }}
                mr={2}
              >Anciens combats
              </Tab>
              <Tab
                fontWeight="bold"
                bg={colors.tabBg}
                color={colors.tabColor}
                _selected={{ bg: colors.tabSelectedBg, color: colors.tabSelectedColor }}
                mr={2}
              >Prochains combats
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <AgentList initialAgents={groupData.agents} />
              </TabPanel>
              <TabPanel>
                <FightList initialFights={previousFights} myAgents={groupData.agents.map(agent => agent.id)} status={FightStatus.DONE} groups={groups}/>
              </TabPanel>
              <TabPanel>
                <FightList initialFights={upcomingFights} myAgents={groupData.agents.map(agent => agent.id)} status={FightStatus.SCHEDULED} groups={groups}/>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>

        <Box textAlign="center" bg={boxBgColor} borderRadius="lg" padding={6} boxShadow="lg">
          <Image
            src={LeagueLogos[groupData.league]}
            alt="League Icon"
            boxSize="150px"
            margin="0 auto"
          />
          <Heading size="lg" mt={4} mb={6} color={headingColor}>
            Ligue {capitalize(groupData.league)}
          </Heading>
          <Divider />
          <VStack spacing={4} align="stretch">
            {StatsInfos.map((stat, index) => (
              <React.Fragment key={stat.key}>
                <Stat mt={4}>
                  <StatLabel color={statLabelColor}>{stat.title}</StatLabel>
                  <StatNumber color={statNumberColor}>{stat.custom_stat ? groupData.custom_stats[stat.value_key]?? 0 : groupData[stat.value_key]}</StatNumber>
                </Stat>
                <Divider key={`divider-${index}`} />
              </React.Fragment>
            ))}

          </VStack>
        </Box>
      </Grid>

      <BannerSelectionModal
        isOpen={isOpen}
        onClose={onClose}
        onSelect={handleBannerSelect}
      />
    </Container>
  );
};

export default Profile;
