import {
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure
} from '@chakra-ui/react';
import { useState } from 'react';
import { postAgentUpload } from '../api/agents/agents.ts';
import { getStudentItem } from '../api/students/students.ts';
import ErrorPopup from './ErrorPopup';
import SuccessPopup from './SuccessPopup';
import { useAuth } from '../context/AuthContext.jsx';
import { useModal } from '../context/ModalContext.js';

export default function UploadAgentModal() {

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const { authState } = useAuth();
  const { openLoginModal } = useModal();

  const handleOpen = () => {
    if (!authState.student) {
      openLoginModal();
    } else {
      onOpen();
    }
  };

  const handleUploadAgent = async () => {
    const file = document.querySelector('#fileSelector').files[0];
    if (file) {
      if (!file.name.endsWith('.zip')) {
        setErrorMessage('Le fichier doit être un .zip');
        setShowError(true);
        document.querySelector('#fileSelector').value = null;
        return;
      }

      if (file.size > 10 * 1024 * 1024) { // Check if file size is greater than 10MB
        setErrorMessage('Le fichier ne doit pas dépasser 10MB');
        setShowError(true);
        document.querySelector('#fileSelector').value = null;
        return;
      }
    }

    const agentName = document.querySelector('#AgentName').value || 'MinimaxPlayer';

    if (!authState.student) {
      setErrorMessage('Vous devez être connecté pour uploader un agent');
      setShowError(true);
      onClose();
      return;
    }

    if (file) {
      try {
        const responseStudent = await getStudentItem(authState.student.id);
        const group_id = responseStudent.group_id;
        const formData = new FormData();
        formData.append('group_id', group_id);
        formData.append('file', file);
        formData.append('name', agentName);
        
        await postAgentUpload(formData, authState.options);
        setSuccessMessage('Agent uploaded successfully');
        setShowSuccess(true);
        onClose();
      } catch {
        setErrorMessage('Error uploading agent');
        setShowError(true);
        onClose();
      }
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <SuccessPopup message={successMessage} onClose={() => setShowSuccess(false)} isOpen={showSuccess} />
      <ErrorPopup message={errorMessage} onClose={() => setShowError(false)} isOpen={showError} />
      <Button
        as={'a'}
        rounded={'full'}
        px={6}
        colorScheme={'cyan'}
        bg={'cyan.400'}
        color='white'
        onClick={handleOpen}
        _hover={{ bg: 'cyan.500' }}
        cursor={'pointer'}
      >
        Uploadez votre agent
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Uploadez un nouvel agent</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Nom de l&apos;agent</FormLabel>
              <Input id="AgentName" type='text' placeholder="NoNameAgent" />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Fichier (.zip)</FormLabel>
              <Input id="fileSelector" type="file" accept='.zip' border={'0px'} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button bg={'pink.500'} color='white' mr={3} onClick={onClose}>
              Fermer
            </Button>
            <Button bg={'cyan.500'} color='white' onClick={handleUploadAgent}>Upload agent</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
