import React, { useState, useEffect } from 'react';
import {
  Container, Heading, Accordion, useColorModeValue, Box
} from '@chakra-ui/react';
import Loading from './Loading';
import ErrorMessage from './ErrorMessage';
import LeagueAccordionItem from './LeagueAccordionItem';
import { LeagueNames, useColors } from '../constants/constants.js';
import { getGroupCollection } from '../api/groups/groups.ts';

const Leaderboard = () => {
  const [groups, setGroups] = useState(null);
  const textColor = useColorModeValue('black', 'gray.300');
  const colors = useColors();

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const groupStats = await getGroupCollection({ include_stats: true });
        setGroups(groupStats);
      } catch (error) {
        setGroups([]);
        console.error('Error fetching groups:', error);
      }
    };

    fetchGroups();
  }, []);

  if (groups === null) return <Loading />;
  if (groups.length === 0) return <ErrorMessage message="Erreur lors du chargement des données" />;

  const leagues = {};
  LeagueNames.slice().reverse().forEach((name) => {
    leagues[name] = groups.filter(group => group.league_history.slice(-1).toString() === name).sort((a, b) => b.elo - a.elo);
  });

  return (
    <Container maxW={'7xl'} padding={5}>
      <Heading as="h1" size="xl" color={colors.headingColor} mb={6} textAlign={'center'}>
        Classement des ligues
      </Heading>
      <Box maxW="5xl" mx="auto" p={5}>
        <Accordion allowMultiple>
          {Object.keys(leagues).map((league, index) => (
            <LeagueAccordionItem
              key={league}
              league={league}
              index={index}
              leagueGroups={leagues[league]}
              textColor={textColor}
            />
          ))}
        </Accordion>
      </Box>
    </Container>
  );
};

export default Leaderboard;
