import { useToast } from '@chakra-ui/react';
import { PropTypes } from 'prop-types';

import { useAuth } from '../context/AuthContext.jsx';
import { Navigate } from 'react-router-dom';
import Loading from './Loading.js';


const AdminRestrictedRoute = ({ element }) => {
    const { isAdmin, wasConnected } = useAuth();
    const toast = useToast();

    if (isAdmin === null) {
        return <Loading />;
    }

    if (isAdmin) {
        return element;
    } else {
        if (wasConnected) {
            toast({
                title: "Session expirée.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
        return <Navigate to="/" />;
    }
}

AdminRestrictedRoute.propTypes ={
    element: PropTypes.elementType.isRequired
}
export default AdminRestrictedRoute;
