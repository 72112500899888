import React from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, VStack } from '@chakra-ui/react';
import StudentPanel from './StudentPanel';
import GroupPanel from './GroupPanel';
import AgentPanel from './AgentPanel';
import FightPanel from './FightPanel';
import AdminPanel from './AdminPanel';
import { useColors } from '../constants/constants.js';
const AdminDashboard = () => {
  const colors = useColors();
  return (
    <Box p={5} borderRadius="md" mx="auto" w="90%" boxShadow="lg">
      <VStack spacing={5}>
        <Box fontSize="2xl" fontWeight="bold" mb={4} mt={10} color={colors.cyanTextColor}>
          Gestion Admin
        </Box>
        <Tabs variant="soft-rounded" w="100%">
          <TabList justifyContent={'center'} mb={10}>
            <Tab
              fontWeight="bold"
              bg={colors.tabBg}
              color={colors.tabColor}
              _selected={{ bg: colors.tabSelectedBg, color: colors.tabSelectedColor }}
              mr={2}
            >
              Matchmaking
            </Tab>
            <Tab
              fontWeight="bold"
              bg={colors.tabBg}
              color={colors.tabColor}
              _selected={{ bg: colors.tabSelectedBg, color: colors.tabSelectedColor }}
              mr={2}
            >
              Etudiants
            </Tab>
            <Tab
              fontWeight="bold"
              bg={colors.tabBg}
              color={colors.tabColor}
              _selected={{ bg: colors.tabSelectedBg, color: colors.tabSelectedColor }}
              mr={2}
            >
              Groupes
            </Tab>
            <Tab
              fontWeight="bold"
              bg={colors.tabBg}
              color={colors.tabColor}
              _selected={{ bg: colors.tabSelectedBg, color: colors.tabSelectedColor }}
              mr={2}
            >
              Agents
            </Tab>
            <Tab
              fontWeight="bold"
              bg={colors.tabBg}
              color={colors.tabColor}
              _selected={{ bg: colors.tabSelectedBg, color: colors.tabSelectedColor }}
            >
              Combats
            </Tab>
          </TabList>
        <TabPanels>
          <TabPanel>
            <AdminPanel />
          </TabPanel>
          <TabPanel>
            <StudentPanel />
          </TabPanel>
          <TabPanel>
            <GroupPanel />
          </TabPanel>
          <TabPanel>
            <AgentPanel />
          </TabPanel>
          <TabPanel>
            <FightPanel />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
    </Box >
  );
};

export default AdminDashboard;
