/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Abyss API
 * API for the Abyss project
 * OpenAPI spec version: 0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  LoginStudent,
  Student,
  UpdateStudent
} from '.././model'
import { axios_instance } from '.././mutator/axios-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const postStudentCollection = (
    student: Student,
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<Student>(
      {url: `https://abyss-a24.corail-lab.ca/api/students`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: student
    },
      options);
    }
  


export const getPostStudentCollectionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postStudentCollection>>, TError,{data: Student}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof postStudentCollection>>, TError,{data: Student}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postStudentCollection>>, {data: Student}> = (props) => {
          const {data} = props ?? {};

          return  postStudentCollection(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostStudentCollectionMutationResult = NonNullable<Awaited<ReturnType<typeof postStudentCollection>>>
    export type PostStudentCollectionMutationBody = Student
    export type PostStudentCollectionMutationError = unknown

    export const usePostStudentCollection = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postStudentCollection>>, TError,{data: Student}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postStudentCollection>>,
        TError,
        {data: Student},
        TContext
      > => {

      const mutationOptions = getPostStudentCollectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getStudentCollection = (
    
 options?: SecondParameter<typeof axios_instance>,signal?: AbortSignal
) => {
      
      
      return axios_instance<Student[]>(
      {url: `https://abyss-a24.corail-lab.ca/api/students`, method: 'GET', signal
    },
      options);
    }
  

export const getGetStudentCollectionQueryKey = () => {
    return [`https://abyss-a24.corail-lab.ca/api/students`] as const;
    }

    
export const getGetStudentCollectionQueryOptions = <TData = Awaited<ReturnType<typeof getStudentCollection>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStudentCollection>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStudentCollectionQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStudentCollection>>> = ({ signal }) => getStudentCollection(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStudentCollection>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStudentCollectionQueryResult = NonNullable<Awaited<ReturnType<typeof getStudentCollection>>>
export type GetStudentCollectionQueryError = unknown

export const useGetStudentCollection = <TData = Awaited<ReturnType<typeof getStudentCollection>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStudentCollection>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStudentCollectionQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getStudentAdminCheck = (
    
 options?: SecondParameter<typeof axios_instance>,signal?: AbortSignal
) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/students/admin_check`, method: 'GET', signal
    },
      options);
    }
  

export const getGetStudentAdminCheckQueryKey = () => {
    return [`https://abyss-a24.corail-lab.ca/api/students/admin_check`] as const;
    }

    
export const getGetStudentAdminCheckQueryOptions = <TData = Awaited<ReturnType<typeof getStudentAdminCheck>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStudentAdminCheck>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStudentAdminCheckQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStudentAdminCheck>>> = ({ signal }) => getStudentAdminCheck(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStudentAdminCheck>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStudentAdminCheckQueryResult = NonNullable<Awaited<ReturnType<typeof getStudentAdminCheck>>>
export type GetStudentAdminCheckQueryError = unknown

export const useGetStudentAdminCheck = <TData = Awaited<ReturnType<typeof getStudentAdminCheck>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStudentAdminCheck>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStudentAdminCheckQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const postStudentConnected = (
    
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/students/connected`, method: 'POST'
    },
      options);
    }
  


export const getPostStudentConnectedMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postStudentConnected>>, TError,void, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof postStudentConnected>>, TError,void, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postStudentConnected>>, void> = () => {
          

          return  postStudentConnected(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostStudentConnectedMutationResult = NonNullable<Awaited<ReturnType<typeof postStudentConnected>>>
    
    export type PostStudentConnectedMutationError = unknown

    export const usePostStudentConnected = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postStudentConnected>>, TError,void, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postStudentConnected>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getPostStudentConnectedMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const postStudentLoadCsv = (
    
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/students/loadCSV`, method: 'POST'
    },
      options);
    }
  


export const getPostStudentLoadCsvMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postStudentLoadCsv>>, TError,void, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof postStudentLoadCsv>>, TError,void, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postStudentLoadCsv>>, void> = () => {
          

          return  postStudentLoadCsv(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostStudentLoadCsvMutationResult = NonNullable<Awaited<ReturnType<typeof postStudentLoadCsv>>>
    
    export type PostStudentLoadCsvMutationError = unknown

    export const usePostStudentLoadCsv = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postStudentLoadCsv>>, TError,void, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postStudentLoadCsv>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getPostStudentLoadCsvMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const postStudentLogin = (
    loginStudent: LoginStudent,
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/students/login`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: loginStudent
    },
      options);
    }
  


export const getPostStudentLoginMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postStudentLogin>>, TError,{data: LoginStudent}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof postStudentLogin>>, TError,{data: LoginStudent}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postStudentLogin>>, {data: LoginStudent}> = (props) => {
          const {data} = props ?? {};

          return  postStudentLogin(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostStudentLoginMutationResult = NonNullable<Awaited<ReturnType<typeof postStudentLogin>>>
    export type PostStudentLoginMutationBody = LoginStudent
    export type PostStudentLoginMutationError = unknown

    export const usePostStudentLogin = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postStudentLogin>>, TError,{data: LoginStudent}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postStudentLogin>>,
        TError,
        {data: LoginStudent},
        TContext
      > => {

      const mutationOptions = getPostStudentLoginMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteStudentLogout = (
    
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/students/logout`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteStudentLogoutMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteStudentLogout>>, TError,void, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteStudentLogout>>, TError,void, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteStudentLogout>>, void> = () => {
          

          return  deleteStudentLogout(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteStudentLogoutMutationResult = NonNullable<Awaited<ReturnType<typeof deleteStudentLogout>>>
    
    export type DeleteStudentLogoutMutationError = unknown

    export const useDeleteStudentLogout = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteStudentLogout>>, TError,void, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteStudentLogout>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDeleteStudentLogoutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getStudentProfile = (
    
 options?: SecondParameter<typeof axios_instance>,signal?: AbortSignal
) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/students/profile`, method: 'GET', signal
    },
      options);
    }
  

export const getGetStudentProfileQueryKey = () => {
    return [`https://abyss-a24.corail-lab.ca/api/students/profile`] as const;
    }

    
export const getGetStudentProfileQueryOptions = <TData = Awaited<ReturnType<typeof getStudentProfile>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStudentProfile>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStudentProfileQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStudentProfile>>> = ({ signal }) => getStudentProfile(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStudentProfile>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStudentProfileQueryResult = NonNullable<Awaited<ReturnType<typeof getStudentProfile>>>
export type GetStudentProfileQueryError = unknown

export const useGetStudentProfile = <TData = Awaited<ReturnType<typeof getStudentProfile>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStudentProfile>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStudentProfileQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const deleteStudentItem = (
    id: number,
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/students/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteStudentItemMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteStudentItem>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteStudentItem>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteStudentItem>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteStudentItem(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteStudentItemMutationResult = NonNullable<Awaited<ReturnType<typeof deleteStudentItem>>>
    
    export type DeleteStudentItemMutationError = unknown

    export const useDeleteStudentItem = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteStudentItem>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteStudentItem>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getDeleteStudentItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getStudentItem = (
    id: number,
 options?: SecondParameter<typeof axios_instance>,signal?: AbortSignal
) => {
      
      
      return axios_instance<Student>(
      {url: `https://abyss-a24.corail-lab.ca/api/students/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetStudentItemQueryKey = (id: number,) => {
    return [`https://abyss-a24.corail-lab.ca/api/students/${id}`] as const;
    }

    
export const getGetStudentItemQueryOptions = <TData = Awaited<ReturnType<typeof getStudentItem>>, TError = unknown>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStudentItem>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStudentItemQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStudentItem>>> = ({ signal }) => getStudentItem(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStudentItem>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStudentItemQueryResult = NonNullable<Awaited<ReturnType<typeof getStudentItem>>>
export type GetStudentItemQueryError = unknown

export const useGetStudentItem = <TData = Awaited<ReturnType<typeof getStudentItem>>, TError = unknown>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStudentItem>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStudentItemQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const patchStudentItem = (
    id: number,
    updateStudent: UpdateStudent,
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/students/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateStudent
    },
      options);
    }
  


export const getPatchStudentItemMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchStudentItem>>, TError,{id: number;data: UpdateStudent}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchStudentItem>>, TError,{id: number;data: UpdateStudent}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchStudentItem>>, {id: number;data: UpdateStudent}> = (props) => {
          const {id,data} = props ?? {};

          return  patchStudentItem(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchStudentItemMutationResult = NonNullable<Awaited<ReturnType<typeof patchStudentItem>>>
    export type PatchStudentItemMutationBody = UpdateStudent
    export type PatchStudentItemMutationError = unknown

    export const usePatchStudentItem = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchStudentItem>>, TError,{id: number;data: UpdateStudent}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchStudentItem>>,
        TError,
        {id: number;data: UpdateStudent},
        TContext
      > => {

      const mutationOptions = getPatchStudentItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    