/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Abyss API
 * API for the Abyss project
 * OpenAPI spec version: 0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Agent,
  AgentPost,
  UpdateAgent,
  UploadAgent
} from '.././model'
import { axios_instance } from '.././mutator/axios-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const postAgentCollection = (
    agentPost: AgentPost,
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<Agent>(
      {url: `https://abyss-a24.corail-lab.ca/api/agents`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: agentPost
    },
      options);
    }
  


export const getPostAgentCollectionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAgentCollection>>, TError,{data: AgentPost}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof postAgentCollection>>, TError,{data: AgentPost}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAgentCollection>>, {data: AgentPost}> = (props) => {
          const {data} = props ?? {};

          return  postAgentCollection(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostAgentCollectionMutationResult = NonNullable<Awaited<ReturnType<typeof postAgentCollection>>>
    export type PostAgentCollectionMutationBody = AgentPost
    export type PostAgentCollectionMutationError = unknown

    export const usePostAgentCollection = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAgentCollection>>, TError,{data: AgentPost}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postAgentCollection>>,
        TError,
        {data: AgentPost},
        TContext
      > => {

      const mutationOptions = getPostAgentCollectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getAgentCollection = (
    
 options?: SecondParameter<typeof axios_instance>,signal?: AbortSignal
) => {
      
      
      return axios_instance<Agent[]>(
      {url: `https://abyss-a24.corail-lab.ca/api/agents`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAgentCollectionQueryKey = () => {
    return [`https://abyss-a24.corail-lab.ca/api/agents`] as const;
    }

    
export const getGetAgentCollectionQueryOptions = <TData = Awaited<ReturnType<typeof getAgentCollection>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgentCollection>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAgentCollectionQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgentCollection>>> = ({ signal }) => getAgentCollection(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAgentCollection>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAgentCollectionQueryResult = NonNullable<Awaited<ReturnType<typeof getAgentCollection>>>
export type GetAgentCollectionQueryError = unknown

export const useGetAgentCollection = <TData = Awaited<ReturnType<typeof getAgentCollection>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgentCollection>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAgentCollectionQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const postAgentLoadCsv = (
    
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/agents/loadCSV`, method: 'POST'
    },
      options);
    }
  


export const getPostAgentLoadCsvMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAgentLoadCsv>>, TError,void, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof postAgentLoadCsv>>, TError,void, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAgentLoadCsv>>, void> = () => {
          

          return  postAgentLoadCsv(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostAgentLoadCsvMutationResult = NonNullable<Awaited<ReturnType<typeof postAgentLoadCsv>>>
    
    export type PostAgentLoadCsvMutationError = unknown

    export const usePostAgentLoadCsv = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAgentLoadCsv>>, TError,void, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postAgentLoadCsv>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getPostAgentLoadCsvMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const postAgentUpload = (
    uploadAgent: UploadAgent,
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/agents/upload`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: uploadAgent
    },
      options);
    }
  


export const getPostAgentUploadMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAgentUpload>>, TError,{data: UploadAgent}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof postAgentUpload>>, TError,{data: UploadAgent}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAgentUpload>>, {data: UploadAgent}> = (props) => {
          const {data} = props ?? {};

          return  postAgentUpload(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostAgentUploadMutationResult = NonNullable<Awaited<ReturnType<typeof postAgentUpload>>>
    export type PostAgentUploadMutationBody = UploadAgent
    export type PostAgentUploadMutationError = unknown

    export const usePostAgentUpload = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAgentUpload>>, TError,{data: UploadAgent}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postAgentUpload>>,
        TError,
        {data: UploadAgent},
        TContext
      > => {

      const mutationOptions = getPostAgentUploadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteAgentItem = (
    id: number,
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/agents/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteAgentItemMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAgentItem>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteAgentItem>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAgentItem>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteAgentItem(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteAgentItemMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAgentItem>>>
    
    export type DeleteAgentItemMutationError = unknown

    export const useDeleteAgentItem = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAgentItem>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteAgentItem>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getDeleteAgentItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getAgentItem = (
    id: number,
 options?: SecondParameter<typeof axios_instance>,signal?: AbortSignal
) => {
      
      
      return axios_instance<Agent>(
      {url: `https://abyss-a24.corail-lab.ca/api/agents/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAgentItemQueryKey = (id: number,) => {
    return [`https://abyss-a24.corail-lab.ca/api/agents/${id}`] as const;
    }

    
export const getGetAgentItemQueryOptions = <TData = Awaited<ReturnType<typeof getAgentItem>>, TError = unknown>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgentItem>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAgentItemQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgentItem>>> = ({ signal }) => getAgentItem(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAgentItem>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAgentItemQueryResult = NonNullable<Awaited<ReturnType<typeof getAgentItem>>>
export type GetAgentItemQueryError = unknown

export const useGetAgentItem = <TData = Awaited<ReturnType<typeof getAgentItem>>, TError = unknown>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgentItem>>, TError, TData>>, request?: SecondParameter<typeof axios_instance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAgentItemQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const patchAgentItem = (
    id: number,
    updateAgent: UpdateAgent,
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<Agent>(
      {url: `https://abyss-a24.corail-lab.ca/api/agents/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateAgent
    },
      options);
    }
  


export const getPatchAgentItemMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchAgentItem>>, TError,{id: number;data: UpdateAgent}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchAgentItem>>, TError,{id: number;data: UpdateAgent}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchAgentItem>>, {id: number;data: UpdateAgent}> = (props) => {
          const {id,data} = props ?? {};

          return  patchAgentItem(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchAgentItemMutationResult = NonNullable<Awaited<ReturnType<typeof patchAgentItem>>>
    export type PatchAgentItemMutationBody = UpdateAgent
    export type PatchAgentItemMutationError = unknown

    export const usePatchAgentItem = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchAgentItem>>, TError,{id: number;data: UpdateAgent}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchAgentItem>>,
        TError,
        {id: number;data: UpdateAgent},
        TContext
      > => {

      const mutationOptions = getPatchAgentItemMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const putAgentDelete = (
    id: number,
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<void>(
      {url: `https://abyss-a24.corail-lab.ca/api/agents/${id}/deleteAgent`, method: 'PUT'
    },
      options);
    }
  


export const getPutAgentDeleteMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putAgentDelete>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof putAgentDelete>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putAgentDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  putAgentDelete(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutAgentDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof putAgentDelete>>>
    
    export type PutAgentDeleteMutationError = unknown

    export const usePutAgentDelete = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putAgentDelete>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof putAgentDelete>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getPutAgentDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const putAgentToggleActive = (
    id: number,
 options?: SecondParameter<typeof axios_instance>,) => {
      
      
      return axios_instance<Agent>(
      {url: `https://abyss-a24.corail-lab.ca/api/agents/${id}/toggleActive`, method: 'PUT'
    },
      options);
    }
  


export const getPutAgentToggleActiveMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putAgentToggleActive>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationOptions<Awaited<ReturnType<typeof putAgentToggleActive>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putAgentToggleActive>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  putAgentToggleActive(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutAgentToggleActiveMutationResult = NonNullable<Awaited<ReturnType<typeof putAgentToggleActive>>>
    
    export type PutAgentToggleActiveMutationError = unknown

    export const usePutAgentToggleActive = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putAgentToggleActive>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof axios_instance>}
): UseMutationResult<
        Awaited<ReturnType<typeof putAgentToggleActive>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getPutAgentToggleActiveMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
